<template>
  <div class="base-page-outer">
    <div class="conten-outer">
      <approval-agent ref="agent" />
      <div class="btns">
        <!-- <el-button @click="showTimeSelectTime = false">取 消</el-button> -->
        <el-button type="primary" @click="userCenterOk" width="200rem"
          >确定</el-button
        >
      </div>
    </div>
    <!-- <el-drawer
      :size="isMobile ? '100%' : '500px'"
      direction="rtl"
      title="审批详情"
      :visible.sync="processVisible"
    >
      <instance-preview
        v-if="processVisible"
        :instance-id="selectInstance"
        @handler-after="processVisible = false"
      />
    </el-drawer> -->
    <org-picker
      title="请选择要切换的用户"
      :pc-mode="!isMobile"
      type="user"
      ref="orgPicker"
      :selected="nowSelected"
      @ok="selected"
    />
    <!-- <w-dialog
      title="个人中心"
      v-model="userCenterVisible"
      @ok="userCenterOk"
      width="90vw"
    >
      <approval-agent ref="agent" />
      <el-tabs
        defaultActive="userInfo"
        v-if="userCenterVisible"
        v-model="activeTab"
        tab-position="left"
        style="height: 220px"
      >
        <el-tab-pane name="userInfo" label="个人信息">
          <user-info />
        </el-tab-pane>
        <el-tab-pane name="approvalAgent" label="审批代理">
          <approval-agent ref="agent" />
        </el-tab-pane>
        <el-tab-pane name="other" label="其他设置">暂无</el-tab-pane>
      </el-tabs>
    </w-dialog> -->
  </div>
</template>

<script>
import { login } from "@/api/sys";
import UserInfo from "./my/UserInfo";
import ApprovalAgent from "./my/ApprovalAgent";
import { getUserNotify, readNotify } from "@/api/notify";
import OrgPicker from "../../components/common/OrgPicker";
import InstancePreview from "./approval/ProcessInstancePreview";

export default {
  name: "workSpace",
  components: { OrgPicker, InstancePreview, UserInfo, ApprovalAgent },
  data() {
    return {
      isCollapse: false,
      timer: null,
      params: {
        pageSize: 5,
        pageNo: 1,
      },
      notify: {
        records: [],
      },
      activeTab: "approvalAgent",
      selectInstance: null,
      processVisible: false,
      userCenterVisible: false,
    };
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  mounted() {
    if (this.isMobile) {
      this.isCollapse = true;
    }
  },
  computed: {
    loginUser() {
      return this.$store.state.loginUser;
    },
    isMobile() {
      //return window.screen.width < 450
      return true;
    },
    showMenu() {
      if (this.isMobile) {
        return !this.isCollapse;
      }
      return true;
    },
    user() {
      return this.$store.state.loginUser;
    },
    nowSelected() {
      if (this.user.id) {
        return [this.user];
      } else {
        return [];
      }
    },
    active() {
      if (this.$route.path === "/workspace") {
        return this.$router.push("/workspace/forms");
      }
      return this.$route.path;
    },
  },
  methods: {
    getUserNotify() {
      getUserNotify(this.params)
        .then((res) => {
          this.notify = res.data;
        })
        .catch((err) => {
          if (this.timer) {
            clearInterval(this.timer);
          }
          this.$err(err, "获取通知消息失败");
        });
    },
    exitLogin() {
      //console.log("推出登录");
      this.$store.state.loginUser = {};
      //localStorage.setItem('loginUser', "")
      localStorage.removeItem("loginUser");
      localStorage.removeItem("wflow-token");
      //   localStorage.setItem('wflow-token', "")
      // window.navigation.reload()
    },
    userCenterOk() {
      //if (this.activeTab === "approvalAgent") {
      this.$confirm(
        "在代理时间内您的所有审批任务都将转交给审批代理人，确定要提交设置吗?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.$refs.agent.setUserAgent(() => {
          this.userCenterVisible = false;
        });
      });
      // }
    },
    showUserCenter() {
      this.userCenterVisible = true;
    },
    switchUser() {
      this.$refs.orgPicker.show();
    },
    toNotify(msg) {
      if (this.$isNotEmpty(msg.instanceId)) {
        this.selectInstance = msg.instanceId;
        this.processVisible = true;
        this.readNotify(msg.id);
      }
    },
    readNotify(id) {
      let list = id ? [id] : this.notify.records.map((n) => n.id);
      readNotify(list)
        .then((rsp) => {
          this.$ok(rsp, "已读成功");
          this.getUserNotify();
        })
        .catch((err) => {
          this.$err(err, "已读失败");
        });
    },
    timerGetNotify(cycle) {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setInterval(() => this.getUserNotify(), cycle * 1000);
    },
    selected(user) {
      if (user.length > 0) {
        login(user[0].id)
          .then((rsp) => {
            let user = {
              id: rsp.userId,
              name: rsp.userName,
              avatar: rsp.avatar,
              position: rsp.position,
              type: "user",
            };
            this.$store.state.loginUser = user;
            localStorage.setItem("loginUserHotel", JSON.stringify(user));
            localStorage.setItem("wflow-token1", rsp.token);
            window.navigation.reload();
          })
          .catch((e) => {
            this.$err(err, "登录失败");
          });
      }
    },
  },
  computed: {
    loginUser() {
      return this.$store.state.loginUser;
    },
    isMobile() {
      //return window.screen.width < 450
      return true;
    },
    showMenu() {
      if (this.isMobile) {
        return !this.isCollapse;
      }
      return true;
    },
    user() {
      return this.$store.state.loginUser;
    },
    nowSelected() {
      if (this.user.id) {
        return [this.user];
      } else {
        return [];
      }
    },
    active() {
      if (this.$route.path === "/workspace") {
        return this.$router.push("/workspace/forms");
      }
      return this.$route.path;
    },
  },
  watch: {
    params: {
      deep: true,
      handler() {
        this.getUserNotify();
      },
    },
  },
};
</script>

<style lang="less" scoped>
.conten-outer {
  .btns {
    display: flex;
    justify-content: center;
    margin-top: 10vh;
    /deep/ .el-button--primary {
      width: 60vw;
      border-radius: 2rem;
    }
  }
}
.base-page-outer {
  width: 100%;
  height: 100%;
}
@header-height: 80px;

.notify {
  max-height: 200px;
  background: @theme-aside-bgc;
  overflow-y: auto;
  .notify-item:last-child {
    border-bottom: 2px solid @theme-aside-bgc;
  }
  .notify-item {
    border-top: 2px solid @theme-aside-bgc;
    padding: 5px;
    background: white;
    position: relative;
    border-radius: 5px;
    .notify-item-title {
      cursor: pointer;
      color: #3b3b3b;
      &:hover {
        color: @theme-primary;
      }
    }

    .notify-item-content {
      color: #8c8c8c;
      padding: 5px 0;
      font-size: smaller;
    }

    .notify-item-time {
      position: absolute;
      right: 45px;
      top: 7px;
      font-size: 12px;
      color: #8c8c8c;
    }
    .notify-btn {
      position: absolute;
      right: 5px;
      top: 8px;
    }
    .notify-item-type-icon {
      font-size: 18px;
    }
  }
}

.notify-action {
  display: flex;
  justify-content: space-between;
}

.el-menu-vertical:not(.el-menu--collapse) {
  width: 200px;
  //min-height: 400px;
}

.m-aside-menu {
  position: fixed;
  z-index: 999;
  height: 100vh;
}

.aside-menu {
  background: @theme-aside-bgc;
  width: auto !important;

  .logo {
    height: @header-height;
    line-height: @header-height;
    display: flex;
    align-items: center;
    margin-left: 30px;

    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      background: white;
      padding: 5px;
      border-radius: 12px;
      box-shadow: 0 0 8px 0px #e4e4e4;
    }

    span {
      font-size: 14px;
      font-weight: bold;
      color: black;
    }
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 16px;
  }
}

/deep/ .el-menu {
  padding: 0 10px;
  background: @theme-aside-bgc;

  .el-menu-item {
    height: 40px;
    line-height: 40px;
    margin: 4px 0;
    border-radius: 7px;
  }

  .el-divider--horizontal {
    margin: 12px 0;
  }

  .el-menu-item.is-active {
    background: #e6f1fc;
  }
}

/deep/ .el-badge {
  line-height: normal;
}

/deep/ .container {
  background: white;
  height: 100vh;

  .el-header {
    position: relative;
    line-height: @header-height;
    // border-bottom: 1px solid #eeeeee;

    .action {
      position: absolute;
      height: @header-height;
      line-height: @header-height;

      i {
        cursor: pointer;
        padding: 5px;
      }
    }
  }

  .user-avatar {
    cursor: pointer;
    position: absolute;
    right: 30px;
    height: 60px;
  }

  .message {
    position: absolute;
    right: 170px;

    i {
      font-size: 18px;
      color: #a2a4a8;
      padding: 1px;
      cursor: pointer;

      &:hover {
        color: #6d6e70;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  /deep/ .el-main {
    .group {
      margin: 0 0 15px 0px !important;
    }
  }
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: white;
}

::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background-color: #e8e8e8;
}
</style>
